<template>
	<keep-alive>
		<component
			:is="selectedComponent"
			:course="course"
			:selectedQuiz="selectedQuiz"
			:message="message"
			:selectedApprovalRate="selectedApprovalRate"
			@selectComponent="selectComponent"
			@setValue="setValue"
			@setMessage="setMessage"
		>
		</component>
	</keep-alive>
</template>
<script>
import CoursePublishStep1 from "./CoursePublishStep1.vue";
import CoursePublishStep2 from "./CoursePublishStep2.vue";
import CoursePublishStep3 from "./CoursePublishStep3.vue";
import CoursePublishStep4 from "./CoursePublishStep4.vue";
import { get } from "@/util/requests/get";
import { mapGetters } from "vuex";

export default {
	name: "NewCourse",
	components: {
		CoursePublishStep1,
		CoursePublishStep2,
		CoursePublishStep3,
		CoursePublishStep4
	},
	computed: {
		...mapGetters(["allQuizzes"]),
		selectedQuiz() {
			if (this.selectedQuizId === 0) return;
			return this.allQuizzes.find(el => el.id === this.selectedQuizId);
		}
	},
	data() {
		return {
			selectedComponent: "CoursePublishStep1",
			course: "",
			selectedQuizId: "",
			selectedApprovalRate: "",
			message: ""
		};
	},
	methods: {
		setMessage(val) {
			this.message = val;
		},
		async getCourse() {
			const courseId = this.$route.params.id;
			const response = await get(`/course/${courseId}`);
			if (response.status === 200) {
				const {
					data: { data }
				} = response;
				this.course = data;
			} else {
				this.$router.push({ name: "CourseEditor" });
			}
		},
		setValue({ key, value }) {
			this[key] = value;
		},
		selectComponent(cmp) {
			this.selectedComponent = cmp;
		}
	},
	created() {
		this.getCourse();
	}
};
</script>

<style></style>
