<template>
	<v-container class="main-container">
		<v-row>
			<v-col class="d-flex justify-center align-center">
				<admin-default-header
					>Course Name has been published!</admin-default-header
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex justify-center">
				<v-btn
					class="admin-primary-button primary-contrast-background"
					@click="goEditor"
					>Back to Editor</v-btn
				>
				<v-btn
					class="admin-primary-button primary-contrast-background ml-6"
					@click="goAnalytics"
					>See analytics</v-btn
				>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";

export default {
	components: {
		AdminDefaultHeader
	},
	name: "CoursePublishStep4",
	props: [],
	computed: {},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			}
		};
	},
	methods: {
		async validate() {
			return await this.$refs.form.validate();
		},
		goEditor() {
			this.$router.push({
				name: "CourseEditor",
				params: {
					id: this.$route.params.id
				}
			});
		},
		goAnalytics() {
			console.log("go analytics");
		}
	}
};
</script>

<style></style>
