<template>
	<v-container class="main-container">
		<v-row>
			<v-col cols="8">
				<admin-default-subheader class="mb-4">{{
					courseName
				}}</admin-default-subheader>
				<admin-default-header
					>Assign users to this course</admin-default-header
				>
				<section class="mt-12 mb-8">
					<admin-default-subheader
						>Users and groups assigned for this
						course</admin-default-subheader
					>
				</section>

				<v-form ref="form" class="mt-15">
					<label class="admin-default-label primary-text"
						>Search for Users or Groups</label
					>
					<v-autocomplete
						class="search-course"
						placeholder="Start typing to Search"
						filled
					></v-autocomplete>
				</v-form>
			</v-col>
			<v-col cols="4" class="d-flex justify-end">
				<admin-default-subheader> Step 2 of 3 </admin-default-subheader>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<FormMessage v-if="message">{{ message }}</FormMessage>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex justify-end">
				<v-btn
					class="admin-primary-button primary-contrast-background"
					@click="goBack"
					>Back</v-btn
				>
				<v-btn
					class="admin-primary-button primary-contrast-background ml-6"
					@click="goNext"
					>Next</v-btn
				>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import FormMessage from "@/components/FormMessage.vue";

export default {
	components: {
		AdminDefaultHeader,
		AdminDefaultSubheader,
		FormMessage
	},
	name: "CoursePublishStep2",
	props: ["course", "message"],
	computed: {
		courseName() {
			return this.course?.title;
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			}
		};
	},
	methods: {
		async validate() {
			return await this.$refs.form.validate();
		},
		goBack() {
			this.$emit("selectComponent", "CoursePublishStep1");
		},
		async goNext() {
			this.$emit("setMessage", "");
			if ((await this.validate()) === false) {
				this.$emit("setMessage", "Check fields");
				return;
			}
			this.$emit("selectComponent", "CoursePublishStep3");
		}
	}
};
</script>

<style></style>
