var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"main-container"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('admin-default-subheader',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.courseName))]),_c('admin-default-header',[_vm._v("Review")]),_c('section',{staticClass:"mt-15 pt-7 mb-8"},[_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('admin-default-subheader',[_vm._v("Quiz Assigned for this course")]),_c('v-icon',{staticClass:"ml-4",on:{"click":function($event){return _vm.$emit(
									'selectComponent',
									'CoursePublishStep1'
								)}}},[_vm._v("mdi-pencil-outline")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.selectedQuizName))]),_c('span',{staticClass:"ml-5"},[_vm._v("Approval Rate: "+_vm._s(_vm.selectedApprovalRate))])])],1)],1),_c('section',{staticClass:"mt-15 pt-7 mb-8"},[_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('admin-default-subheader',[_vm._v("Users and groups assigned for this course")]),_c('v-icon',{staticClass:"ml-4",on:{"click":function($event){return _vm.$emit(
									'selectComponent',
									'CoursePublishStep2'
								)}}},[_vm._v("mdi-pencil-outline")])],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"4"}},[_c('admin-default-subheader',[_vm._v(" Step 3 of 3 ")])],1)],1),_c('v-row',[_c('v-col',[(_vm.message)?_c('FormMessage',[_vm._v(_vm._s(_vm.message))]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"admin-primary-button primary-contrast-background",on:{"click":_vm.goBack}},[_vm._v("Back")]),_c('v-btn',{staticClass:"admin-primary-button primary-contrast-background ml-6",on:{"click":_vm.publishCourse}},[_vm._v("Publish")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }