<template>
	<v-container class="main-container">
		<v-row>
			<v-col cols="8">
				<admin-default-subheader class="mb-4">{{
					courseName
				}}</admin-default-subheader>
				<admin-default-header>Review</admin-default-header>
				<section class="mt-15 pt-7 mb-8">
					<v-row>
						<v-col class="d-flex"
							><admin-default-subheader
								>Quiz Assigned for this
								course</admin-default-subheader
							>
							<v-icon
								class="ml-4"
								@click="
									$emit(
										'selectComponent',
										'CoursePublishStep1'
									)
								"
								>mdi-pencil-outline</v-icon
							>
						</v-col>
					</v-row>
					<v-row
						><v-col class="d-flex">
							<span>{{ selectedQuizName }}</span>
							<span class="ml-5"
								>Approval Rate: {{ selectedApprovalRate }}</span
							>
						</v-col>
					</v-row>
				</section>
				<section class="mt-15 pt-7 mb-8">
					<v-row>
						<v-col class="d-flex">
							<admin-default-subheader
								>Users and groups assigned for this
								course</admin-default-subheader
							>
							<v-icon
								class="ml-4"
								@click="
									$emit(
										'selectComponent',
										'CoursePublishStep2'
									)
								"
								>mdi-pencil-outline</v-icon
							>
						</v-col>
					</v-row>
				</section>
			</v-col>
			<v-col cols="4" class="d-flex justify-end">
				<admin-default-subheader> Step 3 of 3 </admin-default-subheader>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<FormMessage v-if="message">{{ message }}</FormMessage>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex justify-end">
				<v-btn
					class="admin-primary-button primary-contrast-background"
					@click="goBack"
					>Back</v-btn
				>
				<v-btn
					class="admin-primary-button primary-contrast-background ml-6"
					@click="publishCourse"
					>Publish</v-btn
				>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import FormMessage from "@/components/FormMessage.vue";
import { get } from "@/util/requests/get";

export default {
	components: {
		AdminDefaultHeader,
		AdminDefaultSubheader,
		FormMessage
	},
	name: "CoursePublishStep3",
	props: ["course", "selectedQuiz", "selectedApprovalRate", "message"],
	computed: {
		courseName() {
			return this.course?.title;
		},
		selectedQuizName() {
			return this.selectedQuiz?.name;
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			}
		};
	},
	methods: {
		goBack() {
			this.$emit("selectComponent", "CoursePublishStep1");
		},
		async publishCourse() {
			this.$store.dispatch("setIsLoading", true);
			this.$emit("setMessage", "");

			try {
				const response = await get(
					`/course_publish/${this.$route.params.id}`
				);
				if (response.status === 200) {
					this.$emit("selectComponent", "CoursePublishStep4");
				}
			} catch (error) {
				this.$emit("setMessage", "");
			}
			this.$store.dispatch("setIsLoading", false);
		}
	}
};
</script>

<style></style>
