<template>
	<v-container class="main-container">
		<v-row>
			<v-col cols="8">
				<admin-default-subheader class="mb-4">{{
					courseName
				}}</admin-default-subheader>
				<admin-default-header
					>Assign a quiz to this course</admin-default-header
				>
				<section class="mt-12 mb-8">
					<span class="primary-text"> Quiz Assigned:</span>
				</section>
				<v-form ref="form">
					<v-row>
						<v-col cols="12">
							<label class="admin-default-label primary-text"
								>Search for My Quizzes</label
							>
							<v-autocomplete
								:rules="[rules.required]"
								class="search-course"
								placeholder="Start typing to Search"
								filled
								v-model="selectedQuizId"
								:type="'text'"
								:items="allQuizzes"
								:itemText="'name'"
								:itemValue="'id'"
							></v-autocomplete>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6">
							<InputSelect
								v-model="selectedApprovalRate"
								:items="approvalRates"
								:rules="[rules.required]"
							>
								<template #label
									>Approval Rate</template
								></InputSelect
							>
						</v-col>
					</v-row>
				</v-form>
				<section>
					<p class="mb-10">OR</p>
					<v-btn class="admin-primary-button" @click="goCreateNewQuiz"
						>Create New Quiz</v-btn
					>
				</section>
			</v-col>
			<v-col cols="4" class="d-flex justify-end">
				<admin-default-subheader> Step 1 of 3 </admin-default-subheader>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<FormMessage v-if="message">{{ message }}</FormMessage>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex justify-end">
				<v-btn
					class="admin-primary-button primary-contrast-background"
					@click="goNext"
					>Next</v-btn
				>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import FormMessage from "@/components/FormMessage.vue";
import InputSelect from "@/components/InputSelect.vue";
import { mapGetters } from "vuex";

export default {
	components: {
		AdminDefaultHeader,
		AdminDefaultSubheader,
		FormMessage,
		InputSelect
	},
	name: "CoursePublishStep1",
	props: ["course", "message"],
	computed: {
		...mapGetters(["allQuizzes"]),
		courseName() {
			return this.course.title;
		},
		courseId() {
			return this.course.id;
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			approvalRates: [
				"10%",
				"20%",
				"30%",
				"40%",
				"50%",
				"60%",
				"70%",
				"80%",
				"90%"
			],
			selectedQuizId: "",
			selectedApprovalRate: ""
		};
	},
	methods: {
		async validate() {
			return await this.$refs.form.validate();
		},
		async goNext() {
			this.$emit("setMessage", "");
			if ((await this.validate()) === false) {
				this.$emit("setMessage", "Check fields");
				return;
			}
			this.$emit("selectComponent", "CoursePublishStep2");
		},
		goCreateNewQuiz() {
			this.$router.push({
				name: "NewQuiz"
			});
		}
	},
	updated() {
		this.$emit("setValue", {
			key: "selectedQuizId",
			value: this.selectedQuizId
		});
		this.$emit("setValue", {
			key: "selectedApprovalRate",
			value: this.selectedApprovalRate
		});
	}
};
</script>

<style></style>
